export type Plan = {
  name: string;
  description: string;
  price: string;
  features: { [featureName: string]: boolean | string };
  plans?: any[];
  limits?: any;
};

export const appEnv = process.env.NEXT_PUBLIC_APP_ENV;

const isProductionProducts = appEnv === 'production' || appEnv === 'staging';

const PRICE_IDS = {
  catalyst: {
    monthly: isProductionProducts ? 'price_1OtOGJHsdqP07xgbCRBouFAm' : 'price_1OoURwHsdqP07xgbPhdfGcI7',
    yearly: isProductionProducts ? 'price_1OtOGJHsdqP07xgbOtaCuZcj' : 'price_1OoUUMHsdqP07xgbmy2SNxWo',
  },
  infinity: {
    monthly: isProductionProducts ? 'price_1OtOGFHsdqP07xgb2UYXNuBD' : 'price_1OoUViHsdqP07xgbDLs34IB4',
    yearly: isProductionProducts ? 'price_1OtOGFHsdqP07xgbGoM6nbUk' : 'price_1OoUW4HsdqP07xgbjgHKMLW4',
  },
};

export const plans: Plan[] = [
  {
    name: 'Spark',
    description: 'Just getting started',
    price: 'Free',
    features: {
      [`My ${process.env.NEXT_PUBLIC_ASSISTANT_NAME} (personal AI assistant)`]: true,
      [`Custom ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited',
      [`Shared ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited up to 3 users',
      'Users in Organization': '3',
      'Messages per month': '50 (100 for corporate emails)',
      'Data storage per chat (words)': '500K',
      'Web pages per chat': '500',
      // 'Tasks per month': 'TBD',
      // 'Automations per month': 'TBD',
      'Advanced analytics': true,
      'Task automation': true,
      Personalization: true,
      'Integrated Google search': true,
      'File uploads & advanced search': true,
      'Connector to Email & Calendar': true,
      'User admin & management': false,
    },
  },
  {
    name: 'Catalyst',
    description: 'Team player',
    price: '$29',
    features: {
      [`My ${process.env.NEXT_PUBLIC_ASSISTANT_NAME} (personal AI assistant)`]: true,
      [`Custom ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited',
      [`Shared ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited',
      'Users in Organization': 'Unlimited',
      'Messages per month': '300',
      'Data storage per chat (words)': '2M',
      'Web pages per chat': '2K',
      // 'Tasks per month': 'TBD',
      // 'Automations per month': 'TBD',
      'Advanced analytics': true,
      'Task automation': true,
      Personalization: true,
      'Integrated Google search': true,
      'File uploads & advanced search': true,
      'Connector to Email & Calendar': true,
      'User admin & management': true,
    },
    limits: {
      chatbot: 20,
      isFree: false,
      appsAllowed: true,
      wordCount: 2000000,
      webpage: 2000,
      message: 300,
      appsAllowedList: ['google_sheets'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$29',
        stripePriceId: PRICE_IDS.catalyst.monthly,
      },
      {
        name: 'Yearly',
        price: '$290',
        save: '$58',
        stripePriceId: PRICE_IDS.catalyst.yearly,
      },
    ],
  },
  {
    name: 'Infinity',
    description: 'All in',
    price: '$99',
    features: {
      [`My ${process.env.NEXT_PUBLIC_ASSISTANT_NAME} (personal AI assistant)`]: true,
      [`Custom ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited',
      [`Shared ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: 'Unlimited',
      'Users in Organization': 'Unlimited',
      'Messages per month': '1K',
      'Data storage per chat (words)': '5M',
      'Web pages per chat': '5K',
      // 'Tasks per month': 'TBD',
      // 'Automations per month': 'TBD',
      'Advanced analytics': true,
      'Task automation': true,
      Personalization: true,
      'Integrated Google search': true,
      'File uploads & advanced search': true,
      'Connector to Email & Calendar': true,
      'User admin & management': true,
    },
    limits: {
      chatbot: 20,
      isFree: false,
      appsAllowed: true,
      wordCount: 5000000,
      webpage: 5000,
      message: 1000,
      appsAllowedList: ['google_sheets'],
    },
    plans: [
      {
        name: 'Monthly',
        price: '$99',
        stripePriceId: PRICE_IDS.infinity.monthly,
      },
      {
        name: 'Yearly',
        price: '$990',
        save: '$198',
        stripePriceId: PRICE_IDS.infinity.yearly,
      },
    ],
  },
  {
    name: 'Enterprise',
    description: '',
    price: 'Contact us',
    features: {
      [`My ${process.env.NEXT_PUBLIC_ASSISTANT_NAME} (personal AI assistant)`]: '',
      [`Custom ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: '',
      [`Shared ${process.env.NEXT_PUBLIC_ASSISTANT_NAME}s`]: '',
      'Users in Organization': '',
      'Messages per month': '',
      'Data storage per chat (words)': '',
      'Web pages per chat': '',
      // 'Tasks per month': 'TBD',
      // 'Automations per month': 'TBD',
      'Advanced analytics': '',
      'Task automation': '',
      Personalization: '',
      'Integrated Google search': '',
      'File uploads & advanced search': '',
      'Connector to Email & Calendar': '',
      'User admin & management': '',
    },
  },
];
